/* Slider */

/* +++++++++++++++++++++++++++++++++ */
/* ++�A��++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++ */
.main_area_01{
	width:100%;
	margin:0 auto 80px auto;
	padding:0 0 0 0;
}
.main_area_01 li{
	padding:0;
}
.main_area_01 li a:focus{ outline:none;}

.sub_area_01{
	width:1200px;
	margin:0 auto;
}
.sub_area_01 li{
	margin:0 10px;
}
.sub_area_01 li img{
	width:100%;
	border:#fff 3px solid;
}
	

.position_01{
	position:relative;
	width:986px;
	margin:0 auto;
}
.position_01 .prev{
	position:absolute;
	top:300px;
	right:900px;
	z-index:9999;
}
.position_01 .next{
	position:absolute;
	top:300px;
	left:900px;
	z-index:9999;
}


.main_area_00{
	width:100%;
	margin:0 auto 80px auto;
	padding:0 0 0 0;
}


.main_area_02{ width:100%;}

@media screen and (max-width: 768px) {

.main_area_01 img{ width:100%;}

.position_01{ display:none;}

}

@media screen and (max-width: 756px) { .main_area_01 li{ width:756px; } }
@media screen and (max-width: 600px) { .main_area_01 li{ width:600px; } }
@media screen and (max-width: 500px) { .main_area_01 li{ width:500px; } }
@media screen and (max-width: 414px) { .main_area_01 li{ width:414px; } }
@media screen and (max-width: 375px) { .main_area_01 li{ width:375px; } }
@media screen and (max-width: 360px) { .main_area_01 li{ width:360px; } }
@media screen and (max-width: 320px) { .main_area_01 li{ width:320px; } }

@media screen and (max-width: 768px) { .main_area_02 li{ width:460px; } }
@media screen and (max-width: 600px) { .main_area_02 li{ width:600px; } }
@media screen and (max-width: 500px) { .main_area_02 li{ width:500px; } }
@media screen and (max-width: 414px) { .main_area_02 li{ width:310px; } }
@media screen and (max-width: 375px) { .main_area_02 li{ width:280px; } }
@media screen and (max-width: 360px) { .main_area_02 li{ width:270px; } }
@media screen and (max-width: 320px) { .main_area_02 li{ width:240px; } }



/* +++++++++++++++++++++++++++++++++ */
/* ++�����L���O++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++ */


/*
.rank_area_01 li{
	width:230px;
	margin:0 20px 0 0;
}
.rank_area_01 li img{
	width:100%;
}
*/

.rank_area_01 .li_left li{
	margin:0 30px 0 0;
}






/* +++++++++++++++++++++++++++++++++ */
/* ++�X�}�z�p++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++ */









/* ���E�̉摜�����x�ݒ� */
.rank .slick-slide:not(.slick-active) {
  -webkit-filter: opacity(50%);
  -moz-filter: opacity(50%);
  -o-filter: opacity(50%);
  -ms-filter: opacity(50%);
  filter: opacity(50%);
  transition: 0.2s linear;
}
/* ++++++++++++++++++++++++++ */

.slick-slider
{
    position: relative;

    display: block;

    -moz-box-sizing: border-box;
         box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;
	z-index:-1;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}